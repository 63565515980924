import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@rainbow-me/rainbowkit/styles.css";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { Algen1, AlgenL2 } from "./network.config";
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  avalanche,
  bsc,
  blast,
  scroll,
  gnosis
} from "wagmi/chains";
import {
  coinbaseWallet,
  metaMaskWallet,
  rainbowWallet,
  walletConnectWallet
} from "@rainbow-me/rainbowkit/wallets";
import { robin } from "./utils/robinWalletConfig";
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const defaultConfig = getDefaultConfig({
  appName: "Vesting App",
  projectId: "9e12c07ab380b86a0093f72477d71b38",
  chains: [
    Algen1,
    AlgenL2,
    mainnet,
    polygon,
    arbitrum,
    avalanche,
    base,
    bsc,
    blast,
    optimism,
    scroll,
    gnosis
  ] as any,
  wallets: [
    {
      groupName: "Popular",
      wallets: [
        robin,
        metaMaskWallet,
        rainbowWallet,
        coinbaseWallet,
        walletConnectWallet
      ]
    }
  ],
  ssr: true // If your dApp uses server side rendering (SSR)
});

root.render(
  <React.StrictMode>
    <WagmiProvider config={defaultConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <App />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
