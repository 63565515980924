import React, { useState, useEffect, CSSProperties, useCallback } from "react";
import Logo from "./logo.jpg";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import {
  useAccount,
  useDisconnect,
  useWaitForTransactionReceipt,
  useWriteContract,
  useSwitchChain as useWagmiSwitchChain
} from "wagmi";
import LockDetails from "./lockDetail";
import { Button } from "@headlessui/react";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { readContract } from "@wagmi/core";
import { http, createConfig, getBalance } from "@wagmi/core";
import abi from "./abi";
import vestingAbi from "./vestingFactoryAbi";
import vetstingListAbi from "./vetstingListAbi";
import { Algen1 } from "./network.config";
import { ToastContainer, toast } from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader";
import "react-toastify/dist/ReactToastify.css";
import { formatEther } from "viem";
export type IChain = 1 | 10 | 56 | 137 | 8453 | 42161 | 8911 | 59144 | 8912

const config = createConfig({
  chains: [Algen1],
  transports: {
    [Algen1.id]: http()
  }
});

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red"
};

interface verstingListItem {
  address: `0x${string}`;
  isValiable: boolean;
  start: number;
  end: number;
  vestedAmount: number;
  lockedTotalAmount: number;
}

const IconBack = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00488 12C8.00488 12.3418 8.13477 12.6289 8.4082 12.8955L13.583 17.9541C13.7881 18.1592 14.0342 18.2617 14.335 18.2617C14.9434 18.2617 15.4355 17.7764 15.4355 17.1748C15.4355 16.8809 15.3125 16.6006 15.0869 16.375L10.5752 12.0068L15.0869 7.63184C15.3125 7.40625 15.4355 7.12598 15.4355 6.8252C15.4355 6.22363 14.9434 5.73828 14.335 5.73828C14.041 5.73828 13.7949 5.84082 13.583 6.0459L8.4082 11.1045C8.12793 11.3848 8.00488 11.665 8.00488 12Z"
        fill="#25292E"
      />
    </svg>
  );
};

function App() {
  // const [isLogin, setLogin] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { isConnected, address, chainId } = useAccount();
  const [isOwner, setIsOwner] = useState(false);
  const [vestedAmout, setVestedAmount] = useState(0);
  const [releasedAmount, setReleasedAmount] = useState(0);
  const [lockedTotalAmount, setLockedTotalAmount] = useState(0);
  const [startTime, setStartTime] = useState("");
  const [currentContractAddress, setCurrentContractAddress] =
    useState<`0x${string}`>("0x");
  const { disconnect } = useDisconnect();
  const { openConnectModal } = useConnectModal();
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [vestingList, setVestingList] = useState<any[]>([]);
  const { switchChainAsync, switchChain: switchChainWagmi } = useWagmiSwitchChain()
  const withSwitchChain = useCallback(
    async (fn: any, switchChainId: number) => {
      if (switchChainId !== chainId) {
        await switchChainAsync({ chainId: switchChainId })
      }
      return fn()
    },
    [chainId]
  )

  const {
    data: hash,
    writeContract,
    isError: isWriteError,
    isPending: isWritePending
  } = useWriteContract();

  const { isError: isWithdrawError, isSuccess: isWithdrawSuccess } =
    useWaitForTransactionReceipt({
      hash
    });

  useEffect(() => {
    if (isWithdrawError || isWriteError) {
      toast.error("Failed to withdraw");
      window.location.reload();
    }
  }, [isWithdrawError, isWriteError]);

  useEffect(() => {
    if (isWithdrawSuccess) {
      setTimeout(() => {
        fetchAllData(currentContractAddress);
        getAllContract();
      }, 0);
      toast.success("Successfully withdrawn");
    }
  }, [isWithdrawSuccess]);

  const getAllContract = async () => {
    try {
      const result: [] = (await readContract(config, {
        abi: vestingAbi,
        address: "0x9c611F8857e9996637C98346A6a71da4D137Ba1f",
        functionName: "getAllVestingWallets",
        args: [],
        chainId: Algen1.id
      })) as [];
      if (!!result) {
        const promiseList = result?.map((i: string) => {
          return (async () => {
            try {
              const r = await readContract(config, {
                abi,
                address: i as `0x${string}`,
                functionName: "owner",
                args: [],
                chainId: Algen1.id
              });
              return r;
            } catch (error) {}
          })();
        });
        Promise.all(promiseList)
          .then((res: any[]) => {
            const isCludeUser = res?.filter(
              (item: string) => item?.toLowerCase() === address?.toLowerCase()
            );

            const idxList: number[] = [];
            const allVestingList = res.forEach((item: string, i: number) =>
              item?.toLowerCase() === address?.toLowerCase()
                ? idxList.push(i)
                : null
            );

            if (res && isCludeUser?.length > 0) {
              setIsOwner(true);
              /**start */
              const _resultList: string[] = [];
              const _vestingList: verstingListItem[] = [];
              result?.forEach((item: string, i: number) =>
                idxList.includes(i) ? _resultList.push(item) : null
              );
              const promiseList2 = _resultList?.map((i2) => {
                return (async () => {
                  try {
                    let _isValiable,
                      _start,
                      _end,
                      _releasedAmount,
                      _lockedTotalAmount;
                    try {
                      const _s = await readContract(config, {
                        abi: vetstingListAbi,
                        address: i2 as `0x${string}`,
                        functionName: "revoked",
                        args: [],
                        chainId: Algen1.id
                      });
                      _isValiable = !_s;
                    } catch (error) {
                      _isValiable = false;
                    }
                    try {
                      const _s = await readContract(config, {
                        abi: vetstingListAbi,
                        address: i2 as `0x${string}`,
                        functionName: "start",
                        args: [],
                        chainId: Algen1.id
                      });
                      _start = Number(_s) * 1000;
                    } catch (error) {
                      _start = 0;
                    }
                    try {
                      const _s = await readContract(config, {
                        abi: vetstingListAbi,
                        address: i2 as `0x${string}`,
                        functionName: "end",
                        args: [],
                        chainId: Algen1.id
                      });
                      _end = Number(_s) * 1000;
                    } catch (error) {
                      _end = 0;
                    }
                    try {
                      const _s = await getReleasable(i2 as `0x${string}`);
                      _releasedAmount = _s || 0;
                    } catch (error) {
                      _releasedAmount = 0;
                    }
                    try {
                      const _s = await getLockedTotalAmount(
                        i2 as `0x${string}`
                      );
                      _lockedTotalAmount = _s;
                    } catch (error) {
                      _lockedTotalAmount = 0;
                    }
                    return _isValiable
                      ? {
                          address: i2,
                          isValiable: _isValiable,
                          start: _start,
                          end: _end,
                          vestedAmount: _releasedAmount,
                          lockedTotalAmount: _lockedTotalAmount
                        }
                      : {};
                  } catch (error) {}
                })();
              });
              Promise.all(promiseList2)
                .then((res2) => {
                  if (res2) {
                    const _sort = res2.sort(
                      (a, b) => (a?.start as number) - (b?.start as number)
                    );
                    setVestingList(_sort);
                  } else {
                    setVestingList([]);
                  }
                  setLoading(false);
                })
                .catch((e) => {
                  setVestingList([]);
                  setLoading(false);
                });
              /** end */
              // fetchAllData(result[res?.lastIndexOf(isCludeUser?.[0])]);
            } else {
              setIsOwner(false);
              setLoading(false);
            }
          })
          .catch((error) => {
            setIsOwner(false);
            setLoading(false);
            console.log("%csrc/App.tsx:73 error", "color: #007acc;", error);
          });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("%csrc/App.tsx:62 error", "color: #007acc;", error);
    }
  };

  const getVestedAmount = async (contractAddress: `0x${string}`) => {
    try {
      const result = await readContract(config, {
        abi,
        address: contractAddress,
        functionName: "released",
        args: [],
        chainId: Algen1.id
      });
      if (
        formatEther(result as bigint) ||
        formatEther(result as bigint) === "0"
      ) {
        return formatEther(result as bigint);
      }
      console.log("%csrc/App.tsx:240 result", "color: #007acc;", result);
      return result;
    } catch (error) {
      throw Error(error as any);
      console.log("%csrc/App.tsx:62 error", "color: #007acc;", error);
    }
  };

  const getReleasable = async (contractAddress: `0x${string}`) => {
    try {
      const result = await readContract(config, {
        abi,
        address: contractAddress,
        functionName: "releasable",
        args: [],
        chainId: Algen1.id
      });
      if (
        formatEther(result as bigint) ||
        formatEther(result as bigint) === "0"
      ) {
        return formatEther(result as bigint);
      }
      return result;
    } catch (error) {
      throw Error(error as any);
      console.log("%csrc/App.tsx:62 error", "color: #007acc;", error);
    }
  };

  const getLockedTotalAmount = async (contractAddress: `0x${string}`) => {
    let balance, released;
    try {
      balance = await getBalance(config, {
        address: contractAddress,
        chainId: Algen1.id
      });
    } catch (error) {
      throw Error(error as any);
      console.log("%csrc/App.tsx:82 error", "color: #007acc;", error);
    }
    try {
      released = await readContract(config, {
        abi,
        address: contractAddress,
        functionName: "released",
        args: [],
        chainId: Algen1.id
      });
    } catch (error) {
      throw Error(error as any);
      console.log("%csrc/App.tsx:62 error", "color: #007acc;", error);
    }
    if (
      (Number(balance?.formatted) || Number(balance?.formatted) === 0) &&
      (formatEther(released as bigint) ||
        formatEther(released as bigint) === "0")
    ) {
      return (
        Number(balance?.formatted) + Number(formatEther(released as bigint))
      );
    } else {
      setLockedTotalAmount(0);
    }
  };

  const getTimeStamp = async (contractAddress: `0x${string}`) => {
    try {
      const result = await readContract(config, {
        abi,
        address: contractAddress,
        functionName: "start",
        args: [],
        chainId: Algen1.id
      });
      if (result) {
        return Number(result as any) * 1000;
      }
    } catch (error) {
      throw Error(error as any);
      console.log("%csrc/App.tsx:62 error", "color: #007acc;", error);
    }
  };

  const withdraw = async () => {
    withSwitchChain(
      ()=> writeContract({
        abi,
        address: currentContractAddress,
        functionName: "release",
        args: [],
        // chainId: Algen1.id
      }),
      Algen1.id
    )
    
  };
  const fetchAllData = (contractAddress: `0x${string}`) => {
    console.log(
      "%csrc/App.tsx:374 contractAddress",
      "color: #007acc;",
      contractAddress
    );
    Promise.all([
      getVestedAmount(contractAddress),
      getReleasable(contractAddress),
      getLockedTotalAmount(contractAddress),
      getTimeStamp(contractAddress)
    ])
      .then((res) => {
        if (res?.filter((item) => item || item === 0)?.length < 1) {
          throw Error(res as any);
        }
        setVestedAmount((res?.[0] as number) || 0);
        setReleasedAmount((res?.[1] as number) || 0);
        setLockedTotalAmount((res?.[2] as number) || 0);
        setStartTime(
          `${new Date(res?.[3] as number)
            ?.toLocaleDateString()
            .split("/")
            .join("-")} ${new Date(res?.[3] as number).toLocaleTimeString()}`
        );
        setTimeout(() => {
          setLoading(false);
          setShowDetail(true);
        }, 0);
      })
      .catch((error) => {
        setIsOwner(false);
        setLoading(false);
        console.log("%csrc/App.tsx:228 error", "color: #007acc;", error);
      });
  };

  useEffect(() => {
    if (address && address.startsWith("0x")) {
      setLoading(true);
      getAllContract();
    }
  }, [address]);

  console.log("%csrc/App.tsx:410 object", "color: #007acc;", vestingList);

  return (
    <div className="App flex flex-col">
      {/* <div>{window.isZapry}</div> */}
      <div
        className={`flex flex-row ${
          showDetail ? "justify-between" : "justify-end"
        } items-center px-[20px] pt-[20px]`}
      >
        {showDetail && (
          <div
            onClick={() => {
              setShowDetail(false);
            }}
            className="w-[32px] h-[32px] flex items-center justify-center"
          >
            <IconBack />
          </div>
        )}
        <ConnectButton chainStatus="none" showBalance={false} />
      </div>
      {!isConnected && (
        <img src={Logo} alt="" className="w-screen object-fit mt-[88px]" />
      )}

      {isConnected && isOwner && (
        <>
          {showDetail ? (
            <>
              <LockDetails
                vestedAmout={vestedAmout}
                address={currentContractAddress}
                releasedAmount={releasedAmount}
                lockedTotalAmount={lockedTotalAmount}
                startTime={startTime}
              />

              <div className="px-[20px] pb-[20px]">
                <Button
                  disabled={releasedAmount <= 0}
                  className={`rounded-xl ${
                    releasedAmount <= 0
                      ? "bg-[#E0E0E0] text-[#9E9E9E]"
                      : "bg-[#000] text-[white]"
                  } w-[100%] h-[40px] leading-[40px]`}
                  onClick={withdraw}
                >
                  确定提取
                </Button>
                <ToastContainer autoClose={1000} />
              </div>
            </>
          ) : (
            <>
              <div className="flex-1 w-[100%] px-[16px] overflow-y-scroll">
                {vestingList?.map((item: verstingListItem, i) => (
                  <div
                    onClick={() => {
                      setLoading(true);
                      setCurrentContractAddress(item.address);
                      fetchAllData(item.address);
                    }}
                    className="mt-[20px] border border-[#f4f4f5] rounded-lg px-[20px] py-[24px] "
                  >
                    <div className="flex flex-row justify-between">
                      <div className="text-[12px] leading-[24px] font-semibold text-[#0E76FD]">{`Algen Network`}</div>
                      <div
                        className={`px-[10px] py-[2px] border ${
                          new Date().getTime() < item.start
                            ? "border-[#FEDF89]"
                            : new Date().getTime() > item.start &&
                              new Date().getTime() < item.end
                            ? "border-[#ABEFC6]"
                            : new Date().getTime() > item.end
                            ? "border-[#B2DDFF]"
                            : ""
                        } ${
                          new Date().getTime() < item.start
                            ? "bg-[#FFFAEB]"
                            : new Date().getTime() > item.start &&
                              new Date().getTime() < item.end
                            ? "bg-[#ECFDF3]"
                            : new Date().getTime() > item.end
                            ? "bg-[#EFF8FF]"
                            : ""
                        } flex gap-[7px] rounded-[12px] flex-row items-center`}
                      >
                        <div
                          className={`w-[6px] h-[6px] ${
                            new Date().getTime() < item.start
                              ? "bg-[#F79009]"
                              : new Date().getTime() > item.start &&
                                new Date().getTime() < item.end
                              ? "bg-[#17B26A]"
                              : new Date().getTime() > item.end
                              ? "bg-[#2E90FA]"
                              : ""
                          } rounded-[3px]`}
                        ></div>
                        <div
                          className={`text-[14px] leading-[20px] ${
                            new Date().getTime() < item.start
                              ? "text-[#B54708]"
                              : new Date().getTime() > item.start &&
                                new Date().getTime() < item.end
                              ? "text-[#067647]"
                              : new Date().getTime() > item.end
                              ? "text-[#175CD3]"
                              : ""
                          }`}
                        >
                          {new Date().getTime() < item.start
                            ? "未开始"
                            : new Date().getTime() > item.start &&
                              new Date().getTime() < item.end
                            ? "释放中"
                            : new Date().getTime() > item.end
                            ? "已完成"
                            : "未知状态"}
                        </div>
                      </div>
                    </div>
                    <div className="mt-[8px] text-[22px] leading-[30px] font-semibold text-[#11181c]">{`锁仓地址${
                      i + 1
                    }`}</div>
                    <div className="mt-[8px] text-[12px] text-[#71717a]">{`${item.address}`}</div>
                    <div className="mt-[8px] flex justify-between text-[12px] text-[#71717a]">
                      <div>{`可提取`}</div>
                      <div className="">{`${item.vestedAmount} ${Algen1?.nativeCurrency?.symbol}`}</div>
                    </div>
                    <div className="mt-[8px] flex justify-between text-[12px] text-[#71717a]">
                      <div>{`锁仓总数`}</div>
                      <div className="">{`${item.lockedTotalAmount} ${Algen1?.nativeCurrency?.symbol}`}</div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
      {isConnected && !isOwner && (
        <div className="flex flex-1 flex-col items-center justify-center">
          <div className="text-[60px] leading-[60px]">😲</div>
          <div className="mt-[12px] mb-[12px] text-[22px]">锁仓不存在！</div>
          <div>请确认登录正确的锁仓权益钱包</div>
        </div>
      )}
      {loading && (
        <div className="fixed top-0 w-screen h-screen bg-[rgba(0,0,0,.2)]">
          <div className="flex w-screen h-screen items-center justify-center">
            <BeatLoader
              color={"#000"}
              loading={loading}
              cssOverride={override}
              size={16}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
