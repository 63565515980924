import {Algen1} from "./network.config";

interface ILockDetails {
  vestedAmout?: number;
  address?: string;
  releasedAmount?: number;
  lockedTotalAmount?: number;
  startTime?: string;
}

const LockDetails = (props: ILockDetails) => {
  const { vestedAmout, address, releasedAmount, lockedTotalAmount, startTime } =
    props;
  return (
    <div className="mt-[20px] px-[20px] flex-1">
      <div className="font-inter font-semibold text-[22px] leading-[30px] mb-[4px]">
        可提取
      </div>
      <div className="flex flex-row mb-[40px]">
        <img src={Algen1.iconUrl} />
        <span className="ml-[4px] font-semibold">
          {releasedAmount} {Algen1?.nativeCurrency?.symbol}
        </span>
      </div>
      <div className="font-inter font-semibold text-[22px] leading-[30px] mb-[4px]">
        锁仓详情
      </div>
      <div className="flex flex-row mb-[40px] break-words break-all">
        {address}
      </div>

      <div className="font-inter font-semibold text-[22px] leading-[30px] mb-[4px]">
        锁仓地址
      </div>
      <div className="flex flex-row mb-[12px]  justify-between">
        <span>锁仓总数</span>
        <span>
          {lockedTotalAmount} {Algen1?.nativeCurrency?.symbol}
        </span>
      </div>
      <div className="flex flex-row mb-[12px]  justify-between">
        <span>开始时间</span>
        <span>{startTime}</span>
      </div>
      <div className="flex flex-row mb-[12px]  justify-between">
        <span>锁定时长</span>
        <span>48 个月</span>
      </div>
      <div className="flex flex-row mb-[12px]  justify-between">
        <span>释放方式</span>
        <span>按季度阶梯释放</span>
      </div>
      <div className="flex flex-row justify-between">
        <span>已释放</span>
        <span>
          {vestedAmout} {Algen1?.nativeCurrency?.symbol}
        </span>
      </div>
    </div>
  );
};

export default LockDetails;
