import AlegIcon from "./assets/algenChainIcon.svg";
export const Algen1 = {
  id: 8911,
  name: "Algen Testnet",
  network: "Algen testnet",
  iconUrl: AlegIcon,
  iconBackground: "#000",
  nativeCurrency: {
    name: "Algen",
    symbol: "ALG",
    decimals: 18
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.algen.network/"],
      webSocket: undefined
    },
    public: {
      http: ["https://rpc.algen.network/"],
      webSocket: undefined
    }
  },
  blockExplorers: {
    default: { name: "AlgenScan", url: "https://scan.algen.network/" }
  },
  testnet: true
};

export const AlgenL2 = {
  id: 8921,
  name: "ALgen L2",
  iconUrl: "https://images.fusion.gallery/algen/ALG2.svg",
  iconBackground: "#fff",
  nativeCurrency: { name: "ALgen", symbol: "ALG", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://rpc.alg2.algen.network/"] }
  },
  blockExplorers: {
    default: { name: "Blockscout", url: "https://scan.alg2.algen.network/" }
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 2070728
    }
  }
};
