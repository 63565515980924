import { Wallet, getWalletConnectConnector } from "@rainbow-me/rainbowkit";
import { getInjectedConnector, hasInjectedProvider } from "./providerTool";
import { isAndroid, isIOS } from "./equipment";
import { MetaMaskWalletOptions } from "@rainbow-me/rainbowkit/dist/wallets/walletConnectors/metaMaskWallet/metaMaskWallet";

const isMetaMaskInjected = hasInjectedProvider({ flag: "isMetaMask" });
const shouldUseWalletConnect = !isMetaMaskInjected;
const getUri = (uri: string) => {
  return isAndroid()
    ? uri
    : isIOS()
    ? `metamask://wc?uri=${encodeURIComponent(uri)}`
    : `https://metamask.app.link/wc?uri=${encodeURIComponent(uri)}`;
};
export const robin = ({
  projectId,
  walletConnectParameters,
}: MetaMaskWalletOptions): Wallet => ({
  id: "robin",
  name: "Robin",
  rdns: "io.robin",
  iconUrl: "https://images.likn.co/algen/Robin.webp",
  iconAccent: "#f6851a",
  iconBackground: "#fff",
  installed: !shouldUseWalletConnect ? isMetaMaskInjected : void 0,
  downloadUrls: {
    android: "https://play.google.com/store/apps/details?id=io.metamask",
    ios: "https://apps.apple.com/us/app/metamask/id1438144202",
    mobile: "https://metamask.io/download",
    qrCode: "https://metamask.io/download",
    chrome:
      "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
    edge: "https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm",
    firefox: "https://addons.mozilla.org/firefox/addon/ether-metamask",
    opera: "https://addons.opera.com/extensions/details/metamask-10",
    browserExtension: "https://metamask.io/download",
  },
  mobile: {
    getUri: shouldUseWalletConnect ? getUri : void 0,
  },
  qrCode: shouldUseWalletConnect
    ? {
        getUri,
        instructions: {
          learnMoreUrl: "https://metamask.io/faqs/",
          steps: [
            {
              description:
                "wallet_connectors.metamask.qr_code.step1.description",
              step: "install",
              title: "wallet_connectors.metamask.qr_code.step1.title",
            },
            {
              description:
                "wallet_connectors.metamask.qr_code.step2.description",
              step: "create",
              title: "wallet_connectors.metamask.qr_code.step2.title",
            },
            {
              description:
                "wallet_connectors.metamask.qr_code.step3.description",
              step: "refresh",
              title: "wallet_connectors.metamask.qr_code.step3.title",
            },
          ],
        },
      }
    : void 0,
  extension: {
    instructions: {
      learnMoreUrl: "https://metamask.io/faqs/",
      steps: [
        {
          description: "wallet_connectors.metamask.extension.step1.description",
          step: "install",
          title: "wallet_connectors.metamask.extension.step1.title",
        },
        {
          description: "wallet_connectors.metamask.extension.step2.description",
          step: "create",
          title: "wallet_connectors.metamask.extension.step2.title",
        },
        {
          description: "wallet_connectors.metamask.extension.step3.description",
          step: "refresh",
          title: "wallet_connectors.metamask.extension.step3.title",
        },
      ],
    },
  },
  createConnector: shouldUseWalletConnect
    ? getWalletConnectConnector({
        projectId,
        walletConnectParameters,
      })
    : getInjectedConnector({
        flag: "isMetaMask",
        target: typeof window !== "undefined" ? window.ethereum : void 0,
      }),
});
