export default [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "beneficiary",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "contractAddress",
        type: "address"
      }
    ],
    name: "CreateVestingWallet",
    type: "event"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    name: "allVestingWallets",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "beneficiary",
        type: "address"
      },
      {
        internalType: "uint64",
        name: "startTimestamp",
        type: "uint64"
      },
      {
        internalType: "uint64",
        name: "durationSeconds",
        type: "uint64"
      },
      {
        internalType: "uint64",
        name: "cliffDuration",
        type: "uint64"
      },
      {
        internalType: "uint64",
        name: "period",
        type: "uint64"
      }
    ],
    name: "deployVestingWallet",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "getAllVestingWallets",
    outputs: [
      {
        internalType: "address[]",
        name: "",
        type: "address[]"
      }
    ],
    stateMutability: "view",
    type: "function"
  }
];
