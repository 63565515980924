import { createConnector } from "wagmi";
import { injected } from "wagmi/connectors";

export const getExplicitInjectedProvider = (flag: any) => {
  if (typeof window === "undefined" || typeof window.ethereum === "undefined")
    return;
  const providers = window.ethereum.providers;
  return providers
    ? providers.find((provider: any) => provider[flag])
    : window.ethereum[flag]
    ? window.ethereum
    : void 0;
};

export const hasInjectedProvider = ({ flag }: { flag: string }) => {
  if (flag && typeof getExplicitInjectedProvider(flag) !== "undefined")
    return true;
  return false;
};

const createInjectedConnector = (provider: any) => {
  return (walletDetails: any) => {
    const injectedConfig = provider
      ? {
          target: () => ({
            id: walletDetails.rkDetails.id,
            name: walletDetails.rkDetails.name,
            provider,
          }),
        }
      : {};
    return createConnector((config) => ({
      ...injected(injectedConfig)(config),
      ...walletDetails,
    }));
  };
};

export const getInjectedConnector = ({
  flag,
  target,
}: {
  flag: string;
  target: any;
}) => {
  const provider = target ? target : getInjectedProvider({ flag });
  return createInjectedConnector(provider);
};

export const getInjectedProvider = ({ flag }: { flag: string }) => {
  if (typeof window === "undefined") return;
  const providers =
    window.ethereum == null ? void 0 : window.ethereum.providers;
  if (flag) {
    const provider = getExplicitInjectedProvider(flag);
    if (provider) return provider;
  }
  if (typeof providers !== "undefined" && providers.length > 0)
    return providers[0];
  return window.ethereum;
};
